import React from 'react'

export default () => {
  return (
    <>
      <p>Masaki Nishikawa</p>
      <p>犬の動画を見て毎日を乗り切っています</p>
      <p>SNSのリンクは以下</p>
      <table>
        <tr>
          <td>
            github: 
          </td>
          <td>
            <a href="https://github.com/chakimar" target="_blank" rel="noopener noreferrer">chakimar</a>
          </td>
        </tr>
        <tr>
          <td>
            qiita: 
          </td>
          <td>
            <a href="https://qiita.com/chakimar" target="_blank" rel="noopener noreferrer">chakimar</a>
          </td>
        </tr>
        <tr>
          <td>
            twitter: 
          </td>
          <td>
            <a href="https://twitter.com/chakimar" target="_blank" rel="noopener noreferrer">@chakimar</a>
          </td>
        </tr>
        <tr>
          <td>
            facebook: 
          </td>
          <td>
            <a href="https://www.facebook.com/masaki.nishikawa.chakimar" target="_blank" rel="noopener noreferrer">masaki.nishikawa.chakimar</a>
          </td>
        </tr>
        <tr>
          <td>
            linkedin: 
          </td>
          <td>
            <a href="https://www.linkedin.com/in/masaki-nishikawa-9b703a190/" target="_blank" rel="noopener noreferrer">masaki-nishikawa</a>
          </td>
        </tr>
      </table>
    </>
  );
}