import React from 'react'
import TerminalInputHeader from './TerminalInputHeader';
import { COMMAND_LIST } from '../utils/Constants';

export default ({ stdIn, stdInRef, handleChangeStdIn,handleSubmit }) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="terminal-input-line">
          <TerminalInputHeader />
          <input
            id="stdIn"
            name="stdIn"
            type="text"
            value={stdIn}
            onChange={handleChangeStdIn}
            className="stdin"
            autoFocus
            autoComplete="on"
            list="command"
            ref={stdInRef}
          />
          <dataList id="command">
            { COMMAND_LIST.map(cmd => (<option value={cmd.name} />))}
          </dataList>
        </div>
      </form>
    </div>
  );
}