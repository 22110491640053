import React from 'react';

export default () => {
  return (
    <>
    <ol>
      <li>生まれる</li>
      <li>保育園で蜂に刺されて蜂恐怖症になる</li>
      <li>小学校の時に転んで目の上を切って、いまだに消えない傷ができる</li>
      <li>中学校でグレンミラーの映画に影響されて吹奏楽部に入る</li>
      <li>蒙古斑が消えなくて焦る</li>
      <li>高校生では音楽の才能がないことに気づいたので帰宅部になる</li>
      <li>肺炎で入院したことをきっかけにテニス部に入る</li>
      <li>勉強してなかったので大学落ちる</li>
      <li>楽しかった浪人時代</li>
      <li>浪人時代も全然勉強してなかったので落ちまくって焦る</li>
      <li>最後のすべり止めに受かったのでそこに行く</li>
      <li>特に目標もなかったので体育会系の部活に入る</li>
      <li>練習きつすぎて血尿が出る</li>
      <li>朝4時に山を走っていた時に包丁おじさんに出会う</li>
      <li>山を走る幽霊と勘違いされる</li>
      <li>周りが強すぎたので部活やめる</li>
      <li>特に目標もなかったのでSEになる</li>
      <li>Javaわからん</li>
      <li>入社後2ヶ月で2年目のJavaスペシャリストという肩書がつく</li>
      <li>入社後3ヶ月で先輩が辞めて一人プロジェクトになる</li>
      <li>Androidアプリ開発プロジェクトに入り無事精神崩壊</li>
      <li>会社辞めるはずが東京転勤になる</li>
      <li>蒙古斑が消えなくて焦る</li>
      <li>そろそろいいかなと思って転職する</li>
      <li>北米に出張するもお呼びでなかったので仕事したくてしょうがなかったけどしょうがなく遊んで帰る。しょうがないね</li>
      <li>後輩に誘われて起業する</li>
    </ol>
    </>
  );
}