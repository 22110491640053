import React from 'react'

export default ({stdOuts}) => {
  return (
    <>
      {stdOuts.map( (line, index) => (
        <div key={index}>
          {line}
        </div>
      ))}
    </>
  );
}