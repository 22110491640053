import React from 'react';

export default () => {
  return (
    <div className="terminal-input-header">
      <div className="hostname">chakimar@net</div>
      <div>:</div>
      <div className="current-directory">/home</div>
      <div>$&nbsp;</div>
    </div>
  );
};