import React from 'react';

const PrivacyPolicyPage = () => (
    <>
        <article>
            <section>
                <h1>プライバシーポリシー</h1>
            </section>
            <section>
                <p>chakimar（以下「当方」）は、スマートフォン用アプリケーション（以下「アプリ」）を開発・運用しています。本アプリのご使用によって、本規約に同意していただいたものとみなします。</p>
            </section>
            <section>
                <h2>情報の収集と利用</h2>
                <p>アプリのご利用に際して、以下の利用者情報を取得いたします。</p>
                <p>当方が配信するアプリでは、広告配信のためにGoogle AdMobを使用する場合がございます。</p>
                <p>取得する情報、利用目的、第三者への提供等の詳細につきましては、以下のプライバシーポリシーのリンクよりご確認ください。</p>
                <p><a href="https://policies.google.com/technologies/ads?hl=ja">AdMob</a></p>
            </section>
            <section>
                <h2>免責事項</h2>
                <p>利用上の不具合・不都合に対して可能な限りサポートを行っておりますが、利用者が本アプリを利用して生じた損害に関して、開発元は責任を負わないものとします。</p>
            </section>

        </article>
    </>
);

export default PrivacyPolicyPage;