import React from 'react';
import { COMMAND_LIST } from '../../utils/Constants';

export default () => {
  return (
    <>
    <p>おはようございます。こんにちは。こんばんは。</p>
    <p>私のホームページへようこそ。</p>
    <p>AmplifyとReactで何か作りたくなったのでホームページを作りました。</p>
    <p>コマンドを入力して操作してください</p>
    <p>コマンドは以下です</p>
    <p>commands</p>
    <table>
      <tr>
        <td>--------------------</td>
        <td>--------------------</td>
      </tr>
      {
        COMMAND_LIST.map(cmd => (
          <tr>
            <td>{cmd.name}</td>
            <td>{cmd.description}</td>
          </tr>
        ))
      }
      <tr>
        <td>--------------------</td>
        <td>--------------------</td>
      </tr>
    </table>
    </>
  );
}