import React, { useRef } from 'react';

import Terminal from '../components/Terminal';

const TerminalPage = () => {
    const stdInRef = useRef(null);

    const focusStdIn = () => {
        stdInRef.current.scrollIntoView();
        stdInRef.current.focus();
    }
    return (
        <div className="Terminal" onClick={focusStdIn}>
            <header className="App-header">
                <p>
                    chakimar home, version 0.0.1 <br />
              © 2020 chakimar
            </p>
            </header>
            <main>
                <Terminal stdInRef={stdInRef} focusStdIn={focusStdIn} />
            </main>
        </div>
    );
};

export default TerminalPage;