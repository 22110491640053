import React from 'react';

export default () => {
  return (
    <>
    <table>
      <tr>
        <td>[1]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java struts ガラケー向けWebシステム開発</td>
      </tr>
      <tr>
        <td>[2]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java Androidブラウザアプリ開発</td>
      </tr>
      <tr>
        <td>[3]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java seaser2 グループ企業向けデータ連携システム開発</td>
      </tr>
      <tr>
        <td>[4]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java struts2 データ連携PKG開発</td>
      </tr>
      <tr>
        <td>[5]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java jax-rs 新製品開発</td>
      </tr>
      <tr>
        <td>[6]</td>
        <td></td>
        <td className="job-status">Stopped</td>
        <td>java spring データ連携PKG開発</td>
      </tr>
      <tr>
        <td>[7]</td>
        <td>-</td>
        <td className="job-status">Stopped</td>
        <td>java j2ee aws データ連携Saas開発</td>
      </tr>
      <tr>
        <td>[8]</td>
        <td>+</td>
        <td className="job-status">Running</td>
        <td>react amplify 旅行プラットフォームSaas開発</td>
      </tr>
    </table>
    </>
  );
}