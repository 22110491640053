import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import './App.css';

import TerminalPage from './pages/TerminalPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={TerminalPage} />
        <Route exact path="/privacy" component={PrivacyPolicyPage} />
      </Router>
    </div>
  );
}

export default App;
