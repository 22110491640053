import React from 'react'

export default () => {
  return (
    <>
      <p>
        {`Jan  1 22:38:41  chakimar  home  : [user.debug]  システム化できてないのでべた書きで見え方確認`}<br />
        {`Jan  1 22:48:33  chakimar  home  : [user.debug]  そのうちフォームつくって登録できるようにする`}
      </p>
    </>
  );
}