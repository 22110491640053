import React, {useState, useRef, useEffect} from 'react';
import TerminalOutput from './TerminalOutput';
import TerminalInput from './TerminalInput';
import TerminalInputHeader from './TerminalInputHeader';
import WelcomeAsciiArt from './WelcomeAsciiArt';
import Help from './commands/Help';
import WhoAmI from './commands/WhoAmI';
import History from './commands/History';
import Jobs from './commands/Jobs';
import Logs from './commands/Logs';

export default ({ stdInRef, focusStdIn }) => {
  const bottomRef = useRef(null);
  const [stdIn, setStdIn] = useState("");
  const [stdOuts, setStdOuts] = useState([]);

  useEffect(() => {
    bottomRef.current.scrollIntoView();
  }, [stdIn]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let history = <div className="terminal-line"><TerminalInputHeader />{stdIn}</div>;
    let commandResult = "";
    if (stdIn === "") {
      commandResult = "";
    } else if(stdIn === "help") {
      commandResult = <Help />
    } else if(stdIn === "whoami") {
      commandResult = <WhoAmI />
    } else if(stdIn === "history") {
      commandResult = <History />
    } else if(stdIn === "jobs") {
      commandResult = <Jobs />
    } else if(stdIn === "tail /var/log/messages") {
      commandResult = <Logs />
    } else {
      commandResult = `${stdIn}: command not found`;
    }
    setStdOuts([...stdOuts, history, commandResult]);
    setStdIn("");
    focusStdIn();
  };

  const handleChangeStdIn = (event) => {
    setStdIn(event.target.value);
  };

  return (
    <div>
      <WelcomeAsciiArt />
      <p>Type `help`</p>
      <TerminalOutput
        stdOuts={stdOuts}
      />
      <TerminalInput
        stdIn={stdIn}
        stdInRef={stdInRef}
        handleSubmit={handleSubmit}
        handleChangeStdIn={handleChangeStdIn}
      />
      <div ref={bottomRef} />
    </div>
  )
};